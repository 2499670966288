import React from 'react'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

export default function ContactForm() {
  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name="contact"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="-mx-2 flex flex-wrap justify-center">
        <div className="mb-4 px-2 w-full sm:w-6/12">
          <input
            className="appearance-none block border border-gray-300 pl-4 pr-10 py-4 rounded w-full"
            name="name"
            placeholder="Your Name"
            type="text"
            required
          />
        </div>
        <div className="mb-4 px-2 w-full sm:w-6/12">
          <input
            className="appearance-none block border border-gray-300 pl-4 pr-10 py-4 rounded w-full"
            name="email address"
            placeholder="Your Email Address"
            type="email"
            required
          />
        </div>
      </div>
      <div className="mb-4">
        <input
          className="appearance-none block border border-gray-300 pl-4 pr-10 py-4 rounded w-full"
          name="subject"
          placeholder="Subject"
          type="text"
        />
      </div>
      <div className="mb-4">
        <textarea
          className="appearance-none block border border-gray-300 pl-4 pr-10 py-4 rounded w-full"
          name="message"
          placeholder="Enter your message..."
          rows="6"
          required
        ></textarea>
      </div>
      <div className="text-right">
        <button
          className="bg-red-600 hover:bg-red-700 inline-block px-6 py-2 rounded-full text-center text-white"
          type="submit"
          onClick={() => {
            trackCustomEvent({
              category: 'Lead',
              action: 'Click',
              label: 'Contact Form Submission'
            })
          }}
        >
          Submit
        </button>
      </div>
    </form>
  )
}
