import React from 'react'

export default function Map() {
  return (
    <section>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3114.426445846318!2d-104.72082898438082!3d38.685047567037444!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87136a45451959ff%3A0x59d91323cba69d9f!2sCarson%20Bluffs%20Self%20Storage!5e0!3m2!1sen!2sus!4v1625800142269!5m2!1sen!2sus"
        height="450"
        frameborder="0"
        allowfullscreen=""
        aria-hidden="false"
        tabindex="0"
        width="100%"
        title="Carson Bluffs Self Storage, located in Fountain, CO"
      ></iframe>
    </section>
  )
}
