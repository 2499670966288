import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactForm from '../components/ContactComponents/Form'
import ContactBar from '../components/ContactComponents/ContactBar'
import Map from '../components/ContactComponents/Map'

export default function ContactPage() {
  return (
    <Layout>
      <Seo
        title="Storage Units Near Fort Carson - Fountain, CO Self Storage"
        description="Contact Carson Bluffs Self Storage today for affordable self storage in Fountain, CO. Located near Fort Carson with easy access to I-25."
      />
      <section className="bg-gray-50 py-16 pt-24">
        <div className="container mx-auto px-4">
          <div className="-mx-4 flex flex-wrap space-y-6 lg:space-y-0">
            <div className="ml-auto mr-auto px-4 w-full lg:w-9/12">
              <h2 className="font-semibold mb-2 text-3xl text-center text-gray-800">
                Get in touch
              </h2>
              <p className="mb-6 text-center">
                Send us a message now. Let us know about your storage needs and
                we will get back to you within one business day with available
                options.
              </p>
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      <ContactBar />
      <Map />
    </Layout>
  )
}
