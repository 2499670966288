import React from 'react'

export default function ContactBar() {
  return (
    <section className="py-12 text-gray-500">
      <div className="container mx-auto px-4 relative">
        <div className="-mx-4 flex flex-wrap leading-relaxed md:divide-gray-300 md:divide-x">
          <div className="p-4 w-full md:w-4/12">
            <div className="lg:px-8">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-10 mb-6 text-red-600 w-10"
              >
                <path d="M9.366 10.682a10.556 10.556 0 0 0 3.952 3.952l.884-1.238a1 1 0 0 1 1.294-.296 11.422 11.422 0 0 0 4.583 1.364 1 1 0 0 1 .921.997v4.462a1 1 0 0 1-.898.995c-.53.055-1.064.082-1.602.082C9.94 21 3 14.06 3 5.5c0-.538.027-1.072.082-1.602A1 1 0 0 1 4.077 3h4.462a1 1 0 0 1 .997.921A11.422 11.422 0 0 0 10.9 8.504a1 1 0 0 1-.296 1.294l-1.238.884zm-2.522-.657l1.9-1.357A13.41 13.41 0 0 1 7.647 5H5.01c-.006.166-.009.333-.009.5C5 12.956 11.044 19 18.5 19c.167 0 .334-.003.5-.01v-2.637a13.41 13.41 0 0 1-3.668-1.097l-1.357 1.9a12.442 12.442 0 0 1-1.588-.75l-.058-.033a12.556 12.556 0 0 1-4.702-4.702l-.033-.058a12.442 12.442 0 0 1-.75-1.588z" />
              </svg>
              <h2 className="font-semibold mb-2 text-gray-800 text-xl">
                Phone
              </h2>
              <ul>
                <li>
                  <a
                    href="tel:+0 123-456-789"
                    className="hover:text-red-600"
                    target="_pg_blank"
                  >
                    +1 (719) 382-0999
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="p-4 w-full md:w-4/12">
            <div className="lg:px-8">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-10 mb-6 text-red-600 w-10"
              >
                <path d="M12 20.9l4.95-4.95a7 7 0 1 0-9.9 0L12 20.9zm0 2.828l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 13a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 2a4 4 0 1 1 0-8 4 4 0 0 1 0 8z"></path>
              </svg>
              <h2 className="font-semibold mb-2 text-gray-800 text-xl">
                Address
              </h2>
              <div>
                10055 Charter Oak Ranch Rd.
                <br /> Fountain, CO 80817&nbsp;
                <br /> United States of America
              </div>
            </div>
          </div>
          <div className="p-4 w-full md:w-4/12">
            <div className="lg:px-8">
              <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-10 mb-6 text-red-600 w-10"
              >
                <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm17 4.238l-7.928 7.1L4 7.216V19h16V7.238zM4.511 5l7.55 6.662L19.502 5H4.511z"></path>
              </svg>
              <h2 className="font-semibold mb-2 text-gray-800 text-xl">
                Email
              </h2>
              <div className="mb-2">
                <a
                  href="mailto:carsonbluffsstorage@gmail.com"
                  className="hover:text-red-600"
                >
                  Email Us
                </a>
              </div>
              {/* <div className="flex-wrap inline-flex space-x-1">
                  <a
                    href="#"
                    className="bg-gray-700 hover:bg-red-600 hover:text-white p-2 rounded-full text-white"
                    aria-label="facebook link"
                  >
                    {' '}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M14 13.5h2.5l1-4H14v-2c0-1.03 0-2 2-2h1.5V2.14c-.326-.043-1.557-.14-2.857-.14C11.928 2 10 3.657 10 6.7v2.8H7v4h3V22h4v-8.5z" />
                    </svg>
                  </a>
                  <a
                    href="#"
                    className="bg-gray-700 hover:bg-red-600 hover:text-white p-2 rounded-full text-white"
                    aria-label="twitter link"
                  >
                    {' '}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
                    </svg>
                  </a>
                  <a
                    href="#"
                    className="bg-gray-700 hover:bg-red-600 hover:text-white p-2 rounded-full text-white"
                    aria-label="instagram link"
                  >
                    {' '}
                    <svg
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
                    </svg>
                  </a>
                </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
